import { useContext } from "react";
import avatarImg from "../../assets/avatar.png";
import { Link } from "react-router-dom";

import { AuthContext } from "../../contexts/auth";
import { FiHome, FiTv, FiUser, FiSettings } from "react-icons/fi";
import './header.css'

export default function Header() {
  const { user } = useContext(AuthContext);

  return (
    <div className="sidebar">
      <div>
        <img
          src={user.avatarUrl === null ? avatarImg : user.avatarUrl}
          alt="foto do usuário"
        />
      </div>
      <Link to="/painel">
      <FiHome color="#FFF" size={24} />
      Inicio
      </Link>
      <Link to="/adicionar-programas">
      <FiTv color="#FFF" size={24} />
      Criar Programa
      </Link>
      <Link to="/perfil">
      <FiSettings color="#FFF" size={24} />
      Perfil
      </Link>
    </div>
    
  );
}
