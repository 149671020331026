import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import "./santamissa.css";

export default function SantaMissa() {
  return (
    <div className="main-container">
      <Navbar />

      <div className="body">
        <div className="container-card">
          <div className="container-textos">
            <h1>Banco do Brasil</h1>
            <p>AGÊNCIA: 0036-1</p>
            <p>CONTA: 165.666-x</p>
            <p>DSLM - Paróquia de São José</p>
            <h1>Pix</h1>
            <p>CNPJ: 08.264.111/0004-60</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
