import { Link } from "react-router-dom";

import "./footer.css";
import logo from "../../assets/logo.png";
import canal from '../../assets/canal.webp';
import { SocialIcon } from "react-social-icons";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <div className="logo">
            <img src={logo} alt="Logo da Tv Cidade Oeste" />
          </div>

          <div className="canal">
            <img src={canal} alt="Canal 172 BrisaNet" />
          </div>

          <div className="programas-footer">
            <ul>
              <Link to={"/programas"}>
                <li>PROGRAMAS</li>
              </Link>
              <Link to={"/quem-somos"}>
                <li>QUEM SOMOS</li>
              </Link>
              <Link to={"/contato"}>
                <li>CONTATO</li>
              </Link>
              <Link to={"www.facebook.com/santa-missa"}>
                <li>SANTA MISSA</li>
              </Link>
            </ul>
          </div>

          <div className="social-media">
            <span>SIGA NOSSAS REDES</span>

            <SocialIcon
              bgColor="white"
              fgColor="#050814"
              style={{ height: 35, width: 35}}
              url="https://www.facebook.com/tvcidadeoeste"
              target="_blank"
            />
            <SocialIcon
              bgColor="white"
              fgColor="#050814"
              style={{ height: 35, width: 35}}
              url="https://www.youtube.com/@TVCidadeOeste"
              target="_blank"
            />
            <SocialIcon
              bgColor="white"
              fgColor="#050814"
              style={{ height: 35, width: 35}}
              url="https://www.instagram.com/tvcidadeoeste/"
              target="_blank"
            />
          </div>
        </div>
        <div className="copyright">
          <span>
            © 2024 TV CIDADE OESTE LTDA - Todos os direitos reservados - Hospedado por <a href="https://www.primedata.com.br">Prime Data</a>
          </span>
        </div>
      </div>
    </div>
  );
}
