import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import "./erro.css";
export default function Erro() {
  return (
    <div className="main-container">
      <Navbar />
      <div className="body-erro">
        <div className="erro">
          <h1>404</h1>
          <h3>Desculpe, algo deu errado.</h3>
         
        </div>
      </div>

      <Footer />
    </div>
  );
}
