import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import VideoPLayer from "../../components/VideoPlayer";

import PageTitle from "../../components/PageTitle";



import "./home.css";
import Headroom from "react-headroom";

export default function Home() {
  return (
    <div className="main-container">
      <Headroom>
        <Navbar />
      </Headroom>
      <div className="body">
        <PageTitle
          title="ASSISTA AO VIVO"
          color="white"
          height={35}
          width={35}
        />
        <div className="video-container">
          <VideoPLayer />
        </div>
      </div>

      <div> 
      </div>
      <Footer />
    </div>
  );
}


