import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import PageTitle from "../../components/PageTitle";

import Fachada2018 from "../../assets/fachada-2018.webp";
import Fachada2023 from "../../assets/fachada-2023.webp";

import "./quem-somos.css";

export default function QuemSomos() {
  return (
    <div className="main-container">
      <Navbar />
      <div className="title-info">
      <PageTitle
        title={"QUEM SOMOS"}
        color={"#f1f1f1"}
        height={35}
        width={35}
      />
      </div>
   
      <div className="body">
        <div className="quem-somos">
          <div className="texto">
            <p>
              A TV Cidade Oeste está no canal 172 da Brisanet telecomunicações
              que está presente nos estados de Alagoas, Bahia, Ceará, Maranhão,
              Paraíba, Pernambuco, Piauí, Rio Grande do Norte e Sergipe.
            </p>
            <p>
              Para atingir todos os públicos e dar mais opções ao telespectador
              fazemos nossas transmissões também para o mundo através da
              internet por meio do site, aplicativo exclusivo e das redes
              sociais.
            </p>

            <p>
              A TV Cidade Oeste foi criada no ano de 2018, possui a sede
              administrativa na cidade de Mossoró/RN e filial na cidade de Pau
              dos Ferros/RN. A emissora tem o propósito de levar o melhor dos
              acontecimentos da região Oeste Potiguar e do estado para o mundo,
              além de ser um prestador de serviços para o cidadão, buscando
              informar, atualizar e também de entreter com conteúdos artísticos
              e culturais.
            </p>

            <p>
              Nosso público alvo são adultos entre 25 a 70 anos que busca
              informação com ética e responsabilidade em programas voltados a
              empresários, políticos, empreendedores, esportistas e no final de
              semana para o público mais jovem entre 07 a 24 anos com programas
              de entretenimento e diversão.
            </p>
            <p>
              Saiba mais sobre os nossos produtos falando diretamente com nosso
              departamento comercial e nos acompanhando nas redes sociais.
            </p>
          </div>
          <div className="image-wrap">
          <div className="images">
            <img
              src={Fachada2018}
              width={345}
              height={210}
              alt="Fachada da Tv Cidade Oeste em 2018"
            />
            <span>Fachada TV Cidade Oeste 2018</span>
          </div>
          <div className="images">
            <img
              src={Fachada2023}
              width={345}
              height={210}
              alt="Fachada da Tv Cidade Oeste em 2023"
            />
            <span>Fachada TV Cidade Oeste 2023</span>
          </div>
        </div>
      
        </div>
        
        <div className="mapa">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15894.847090528314!2d-37.3594416!3d-5.1500152!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ba01151d1fa9b1%3A0xe28d379cede3316d!2sTV%20CIDADE%20OESTE!5e0!3m2!1spt-BR!2sus!4v1695329205770!5m2!1spt-BR!2sus"
              width={800}
              height={310}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
      </div>
      <Footer />
    </div>
  );
}
