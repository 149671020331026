import ReactPlayer from "react-player";
import "./videoplayer.css";

export default function VideoPLayer() {
  return (
    <div className="video">
      <ReactPlayer 
      controls={true}
      url="https://streamer.primedata.com.br:5443/LiveApp/streams/KyvEZUmEQLOn2589462539579.m3u8"
      width="100%"
      height="100%"
      />
    </div>
  );
}
