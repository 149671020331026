import { Routes, Route } from "react-router-dom";

import Home from '../pages/Home';
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Painel from "../pages/Painel";
import Profile from "../pages/Profile";
import Programas from "../pages/Programas";
import Edit from "../pages/Edit"

import Programacao from "../pages/Programacao"
import QuemSomos from "../pages/QuemSomos";
import Contato from "../pages/Contato";
import SantaMissa from "../pages/SantaMissa";

import AssistirPrograma from "../pages/AssistirPrograma";


import Erro from "../pages/Erro";

import Private from './Private';

function RoutesApp(){
    return(
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/signin" element={<SignIn />} /> 
          <Route path="/cadastrar" element={<Private><SignUp /></Private>} />
          <Route path="/painel" element={<Private><Painel /></Private>} />  
          <Route path="/perfil" element={<Private><Profile /></Private>} /> 
          <Route path="/adicionar-programas" element={<Private><Programas /></Private>} />
          <Route path="/adicionar-programas/:id" element={<Private><Programas /></Private>} />
          <Route path="/editar-programa/:id" element={<Private><Edit /></Private>} />


          <Route path="/programas" element={<Programacao />} />
          <Route path="/quem-somos" element={<QuemSomos />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/santa-missa" element={<SantaMissa />} />

          <Route path="/programas/:playlist/:playlistName" element={<AssistirPrograma />} />

          <Route path="*" element={<Erro />} />
        </Routes>
    )
}

export default RoutesApp;