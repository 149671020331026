import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA5wwDJf1TYpCKpRZiqUMSgHBphfrYTzGw",
  authDomain: "tickets-eb8fb.firebaseapp.com",
  projectId: "tickets-eb8fb",
  storageBucket: "tickets-eb8fb.appspot.com",
  messagingSenderId: "327583438593",
  appId: "1:327583438593:web:4bb23a2b81017165daed00",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { auth, db, storage };