import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Title from "../../components/Title";

import { FiTv, FiUpload } from "react-icons/fi";

import { AuthContext } from "../../contexts/auth";
import { db, storage } from "../../services/FirebaseConnection";
import {
  addDoc,
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { toast } from "react-toastify";

const listRef = collection(db, "programas");

export default function Edit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [details, setDetails] = useState("");
  const [playList, setPlaylist] = useState("");
  const [playListName, setPlaylistName] = useState("");

  const [cover, setCover] = useState(null);
  const [coverUrl, setCoverUrl] = useState("");
  const [coverChanged, setCoverChanged] = useState(false);

  const [miniCover, setMiniCover] = useState(null);
  const [miniCoverUrl, setMiniCoverUrl] = useState("");
  const [miniCoverChanged, setMiniCoverChanged] = useState(false);

  const [loadProgramas, setLoadProgramas] = useState(true);
  const [programa, setPrograma] = useState([]);
  const [idCustomer, setIdCustomer] = useState(false);

  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [miniImageUrl, setMiniImageUrl] = useState("");

  useEffect(() => {
    async function loadProgramas() {
      const querySnapshot = await getDocs(listRef)
        .then((snapshot) => {
          let lista = [];
          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              title: doc.data().title,
            });

            if (snapshot.docs.size === 0) {
              console.log("Nenhum programa encontrado.");
              setLoadProgramas(false);
              return;
            }

            setPrograma(lista);
            setLoadProgramas(false);

            if (id) {
              loadId(lista);
            }
          });
        })
        .catch((error) => {
          console.log("Erro ao buscar programa.", error);
          setLoadProgramas(false);
        });
    }

    loadProgramas();
  }, [id]);

  async function loadId(lista) {
    const docRef = doc(db, "programas", id);
    await getDoc(docRef)
      .then((snapshot) => {
        setTitle(snapshot.data().title);
        setSubTitle(snapshot.data().subtitle);
        setDetails(snapshot.data().details);
        setPlaylist(snapshot.data().playlist);
        setPlaylistName(snapshot.data().playlistName);
        setCoverImageUrl(snapshot.data().cover);
        setMiniImageUrl(snapshot.data().miniCover);

        setIdCustomer(true);
      })
      .catch((error) => {
        console.log(error);
        setIdCustomer(false);
      });
  }

  // Handling cover file
  function handleFile(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setCoverImageUrl(URL.createObjectURL(image));

      if (
        image.type === "image/jpeg" ||
        image.type === "image/jpg" ||
        image.type === "image/png"
      ) {
        setCover(image);
        setCoverChanged(true);
      } else {
        alert("Upload deve ser JPEG, JPG ou PNG.");
        setCover(null);
        setCoverChanged(false);
        return;
      }
    }
  }

  // Handling miniCover file
  function handleSecondFile(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setMiniImageUrl(URL.createObjectURL(image));

      if (
        image.type === "image/jpeg" ||
        image.type === "image/jpg" ||
        image.type === "image/png"
      ) {
        setMiniCover(image);
        setMiniCoverChanged(true);
      } else {
        alert("Upload deve ser JPEG, JPG ou PNG.");
        setMiniCover(null);
        setMiniCoverChanged(false);
        return;
      }
    }
  }

  // Handling cover upload only
  async function handleCoverUpload() {
    const uploadRef = ref(storage, `images/programas/${id}/${cover.name}`);
    const uploadTask = uploadBytes(uploadRef, cover);

    return new Promise((resolve, reject) => {
      uploadTask
        .then(async () => {
          const downloadURL = await getDownloadURL(uploadRef);
          resolve({ downloadURL });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Handling miniCover upload only
  async function handleMiniCoverUpload() {
    const miniUploadRef = ref(
      storage,
      `images/programas/${id}/${miniCover.name}`
    );
    const miniUploadTask = uploadBytes(miniUploadRef, miniCover);

    return new Promise((resolve, reject) => {
      miniUploadTask
        .then(async () => {
          const miniDownloadURL = await getDownloadURL(miniUploadRef);
          resolve({ miniDownloadURL });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Editing a program
  async function handleEdit(e) {
    e.preventDefault();
  
    const docRef = doc(db, "programas", id);
  
    const updateData = {
      title: title,
      subtitle: subTitle,
      details: details,
      playlist: playList,
      playlistName: playListName,
    };
  
    if (coverChanged) {
      const { downloadURL } = await handleCoverUpload();
      updateData.cover = downloadURL;
    }
  
    if (miniCoverChanged) {
      const { miniDownloadURL } = await handleMiniCoverUpload();
      updateData.miniCover = miniDownloadURL;
    }
  
    await updateDoc(docRef, updateData)
      .then(() => {
        toast.info("Editado com sucesso.");
        navigate("/painel");
      })
      .catch((error) => {
        console.log("Erro ao atualizar.", error);
        toast.error("Não foi possível editar.");
      });
  }

  // Deleting a program
  async function handleDeleteDoc() {
    const confirmDelete = window.confirm(
      "Você realmente deseja excluir este programa?"
    );

    if (confirmDelete) {
      const docRef = doc(db, "programas", id);
      await deleteDoc(docRef)
        .then(() => {
          toast.info("Deletado com sucesso");
          navigate("/painel");
        })
        .catch((error) => {
          console.log("Erro ao deletar.", error);
          toast.error("Erro ao deletar.");
        });
    }
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Editar Programa">
          <FiTv size={24} />
        </Title>

        <div className="container">
          <form className="form-profile" onSubmit={handleEdit}>
            <label>Nome do programa</label>
            <input
              type="text"
              placeholder="Digite o nome do programa"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <label>Resumo</label>
            <input
              type="text"
              placeholder="Descreva o programa"
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <label>Detalhes</label>
            <input
              type="text"
              placeholder="Insira os detalhes de produção"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />

            <label>Playlist ID</label>
            <input
              type="text"
              placeholder="Insira a chave da playlist do youtube"
              value={playList}
              onChange={(e) => setPlaylist(e.target.value)}
            />
            <label>Nome da Playlist</label>
            <input
              type="text"
              placeholder="Insira o nome da playlist do youtube"
              value={playListName}
              onChange={(e) => setPlaylistName(e.target.value)}
            />
            <div className="upload-files">
              <label>
                Upload da capa
                <input type="file" accept="image/*" onChange={handleFile} />
                {coverImageUrl !== "" ? (
                  <img src={coverImageUrl} width={150} height={150} />
                ) : (
                  <></>
                )}
              </label>
              <span>As capas devem conter o tamanho de 1080x1080 px.</span>

              <label>
                Upload da mini-capa
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleSecondFile}
                />
                {miniImageUrl !== "" ? (
                  <img src={miniImageUrl} width={90} height={160} />
                ) : (
                  <></>
                )}
              </label>
              <span>As mini-capas devem conter o tamanho de 180x320 px.</span>
            </div>
            <button type="submit">
              {id ? "Editar Programa" : "Adicionar Programa"}
            </button>
            {id ? (
              <button
                className="delete-btn"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteDoc();
                }}
              >
                Excluir
              </button>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
