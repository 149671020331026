import "./page-title.css";
import { SocialIcon } from "react-social-icons";

export default function PageTitle({ title, subtitle, height, width, color }) {
  return (
    <div className="main-title">
      <div className="content-container">
        <div className="page-title">
          <h1 style={{ color: color}}>{title}</h1>
         
        </div>
        <div className="social">
          <SocialIcon
            url="https://www.facebook.com/tvcidadeoeste"
            style={{ height: height, width: width }}
            target="_blank"
          />
          <SocialIcon
            url="https://www.youtube.com/@TVCidadeOeste"
            style={{ height: height, width: width }}
            target="_blank"
          />
          <SocialIcon
            url="https://www.instagram.com/tvcidadeoeste/"
            style={{ height: height, width: width }}
            target="_blank"
          />
        </div>
      </div>
    
    </div>
  );
}
