import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Title from "../../components/Title";

import "./programas.css";

import { FiTv, FiUpload } from "react-icons/fi";

import { AuthContext } from "../../contexts/auth";
import { db, storage } from "../../services/FirebaseConnection";
import {
  addDoc,
  collection,

} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { toast } from "react-toastify";

const listRef = collection(db, "programas");

export default function Programas() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [details, setDetails] = useState("");
  const [playList, setPlaylist] = useState("");
  const [playListName, setPlaylistName] = useState("");

  const [cover, setCover] = useState(null);
  const [coverUrl, setCoverUrl] = useState("");

  const [miniCover, setMiniCover] = useState(null);
  const [miniCoverUrl, setMiniCoverUrl] = useState("");

  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [miniImageUrl, setMiniImageUrl] = useState("");

  function handleFile(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setCoverImageUrl(URL.createObjectURL(image));

      if (
        image.type === "image/jpeg" ||
        image.type === "image/jpg" ||
        image.type === "image/png"
      ) {
        setCover(image);
      } else {
        alert("Upload deve ser JPEG, JPG ou PNG.");
        setCover(null);
        return;
      }
    }
  }

  function handleSecondFile(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setMiniImageUrl(URL.createObjectURL(image));

      if (
        image.type === "image/jpeg" ||
        image.type === "image/jpg" ||
        image.type === "image/png"
      ) {
        setMiniCover(image);
      } else {
        alert("Upload deve ser JPEG, JPG ou PNG.");
        setMiniCover(null);
        return;
      }
    }
  }

  async function handleUpload() {
    const uploadRef = ref(storage, `images/programas/${title}/${cover.name}`);
    const miniUploadRef = ref(
      storage,
      `images/programas/${title}/${miniCover.name}`
    );

    const uploadTask = uploadBytes(uploadRef, cover);
    const miniUploadTask = uploadBytes(miniUploadRef, miniCover);

    return Promise.all([uploadTask, miniUploadTask]).then(async () => {
      const downloadURL = await getDownloadURL(uploadRef);
      const miniDownloadURL = await getDownloadURL(miniUploadRef);

      return { downloadURL, miniDownloadURL };
    });
  }

  async function handleCadastro(e) {
    e.preventDefault();

    const currentUid = user.uid;
    if (
      title !== "" &&
      subTitle !== "" &&
      details !== "" &&
      playList !== "" &&
      playListName !== ""
    ) {
      try {
        const { downloadURL, miniDownloadURL } = await handleUpload();

        await addDoc(collection(db, "programas"), {
          createdBy: currentUid,
          title: title,
          subtitle: subTitle,
          details: details,
          playlist: playList,
          playlistName: playListName,
          cover: downloadURL,
          miniCover: miniDownloadURL,
        });

        setTitle("");
        setSubTitle("");
        setDetails("");
        setPlaylist("");
        setPlaylistName("");
        setCoverUrl("");
        setMiniCoverUrl("");
        setCoverImageUrl("");
        setMiniImageUrl("");
        navigate("/painel");
        toast.success("Cadastrado com sucesso.");
      } catch (error) {
        console.error(error);
        toast.error("Erro ao cadastrar!");
      }
    } else {
      toast.error(
        "Não foi possível cadastrar o programa. Preencha todos os campos."
      );
    }
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Adicionar Programa">
          <FiTv size={24} />
        </Title>

        <div className="container">
          <form className="form-profile" onSubmit={handleCadastro}>
            <label>Nome do programa</label>
            <input
              type="text"
              placeholder="Digite o nome do programa"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <label>Resumo</label>
            <input
              type="text"
              placeholder="Descreva o programa"
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <label>Detalhes</label>
            <input
              type="text"
              placeholder="Insira os detalhes de produção"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />

            <label>Playlist ID</label>
            <input
              type="text"
              placeholder="Insira a chave da playlist do youtube"
              value={playList}
              onChange={(e) => setPlaylist(e.target.value)}
            />
            <label>Nome da Playlist</label>
            <input
              type="text"
              placeholder="Insira o nome da playlist do youtube"
              value={playListName}
              onChange={(e) => setPlaylistName(e.target.value)}
            />
            <div className="upload-files">
              <label>
                Upload da capa
                <input type="file" accept="image/*" onChange={handleFile} />
                {coverImageUrl !== "" ? (
                  <img src={coverImageUrl} width={150} height={150} />
                ) : (
                  <></>
                )}
              </label>
              <span>As capas devem conter o tamanho de 1080x1080 px.</span>

              <label>
                Upload da mini-capa
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleSecondFile}
                />
                {miniImageUrl !== "" ? (
                  <img src={miniImageUrl} width={90} height={160} />
                ) : (
                  <></>
                )}
              </label>
              <span>As mini-capas devem conter o tamanho de 180x320 px.</span>
            </div>
            <button type="submit">Adicionar Programa</button>
          </form>
        </div>
      </div>
    </div>
  );
}
