import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { apiKey } from "../../services/YoutubeConnection";
import './assistirprograma.css'

import YouTubePlaylist from "@codesweetly/react-youtube-playlist";

export default function AssistirPrograma() {
const {playlist, playlistName } = useParams();


  return (
    <div className="main-container">
      <Navbar />
      
      <div className="programas-content">
      <div className="playlist">
        
      <YouTubePlaylist
   
        apiKey={apiKey}
        playlistId={playlist}
        uniqueName={playlistName}
      />
      </div>
      </div>
  
    
    </div>
  );
}
