import { useState } from "react";

import { Link, NavLink } from "react-router-dom";
import "./navbar.css";
import { FiMenu, FiX } from "react-icons/fi";
import logo from "../../assets/logo.png";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img
              src={logo}
              alt="Logo Tv Cidade Oeste"
              height={84}
              width={107}
              onClick={closeMobileMenu}
              
            />
            
          </Link>
          <div className="menu-icon" on onClick={handleClick}>
            {click ? <FiX /> : <FiMenu />}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                INÍCIO
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/programas"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                PROGRAMAS
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/quem-somos"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                QUEM SOMOS
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/contato"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                CONTATO
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/santa-missa"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                SANTA MISSA
              </NavLink>
            </li>
          
          </ul>
        </div>
      </nav>
    </div>
  );
}
