import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "./programacao.css";

import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";

import { collection, getDocs, limit, query } from "firebase/firestore";
import { db } from "../../services/FirebaseConnection";

const listRef = collection(db, "programas");

export default function Programacao() {
  const [programas, setProgramas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const [programaData, setProgramaData] = useState({
    title: "",
    subtitle: "",
    details: "",
    cover: "",
    playlist: "",
    playlistName: "",
  });

  useEffect(() => {
    async function loadProgramas() {
      const q = query(listRef, limit(15));
      const querySnapshot = await getDocs(q);
      setProgramas([]);
      await updateState(querySnapshot);

      setLoading(false);
      setIsLoading(false); // Set isLoading to false when data is loaded
    }

    loadProgramas();

    return () => {};
  }, []);

  useEffect(() => {
    // Initialize the states with the data from the first item in the programas array
    if (programas.length > 0) {
      const firstPrograma = programas[0];
      setProgramaData({
        title: firstPrograma.title,
        subtitle: firstPrograma.subtitle,
        details: firstPrograma.details,
        cover: firstPrograma.cover,
        playlist: firstPrograma.playlist,
        playlistName: firstPrograma.playlistName,
      });
    }
  }, [programas]);

  async function updateState(querySnapshot) {
    const isCollectionEmpty = querySnapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      querySnapshot.forEach((doc) => {
        lista.push({
          cover: doc.data().cover,
          title: doc.data().title,
          subtitle: doc.data().subtitle,
          details: doc.data().details,
          miniCover: doc.data().miniCover,
          playlist: doc.data().playlist,
          playlistName: doc.data().playlistName,
        });
      });

      setProgramas((programas) => [...programas, ...lista]);
    } else {
      setIsEmpty(true);
    }
  }

  const handleCardHover = (item) => {
    setProgramaData({
      title: item.title,
      subtitle: item.subtitle,
      details: item.details,
      cover: item.cover,
      playlist: item.playlist,
      playlistName: item.playlistName,
    });
  };

  return (
    <div className="programas-container">
      <div className="main-container">
        <Navbar />
        {isLoading ? (
          // Display loading spinner
          <div className="loading-spinner">
            <Oval
              height={30}
              width={30}
              color="#f1f1f1"
              visible={true}
              secondaryColor="#0755bb"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          // Display content when not loading
          <div className="programas-header">
            <div className="programas-wrap">
              <div className="programas-title">
                <h1>{programaData.title}</h1>
                <h4>{programaData.subtitle}</h4>
                <span>{programaData.details}</span>
                <Link
                  to={`/programas/${programaData.playlist}/${programaData.playlistName}`}
                >
                  Assistir Programas
                </Link>
              </div>
              <div className="cover-programa">
                <img src={programaData.cover} alt="Programa Cover" />
              </div>
            </div>
          </div>
        )}

        {programas.length === 0 ? (
          console.log("Erro ao carregar programas.S")
        ) : (
          
          <div className="listagem">
            <div className="listar-programas">
              {programas.map((item, index) => (
                <div className="card-container" key={index}>
                  <button onMouseEnter={() => handleCardHover(item)}>
                    <div className="image-container">
                      <img src={item.miniCover} alt={item.title} />
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
         
          
        )}

        {/* Show the footer only when loading is done */}
        {!isLoading && <Footer />}
      </div>
      
    </div>
    
  );
}
