import { useState } from "react";
import emailjs from "@emailjs/browser";

import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import Footer from "../../components/Footer";

import { toast } from "react-toastify";

import "./contato.css";

export default function Contato() {
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(false);
    if (nome !== "" && sobrenome !== "" && email !== "" && message !== "") {
      const serviceId = "service_idgbme5";
      const templateId = "template_jajbm79";
      const publicKey = "vErZEmFuYr2huIXwZ";

      // Create a new object that contains dynamic template params
      const templateParams = {
        from_name: nome,
        from_surname: sobrenome,
        from_email: email,
        phone: telefone,
        message: message,
        to_name: "Tv Cidade Oeste",
      };

      await emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log("Email sent.", response);
          setLoading(true);
          toast.success("Email enviado com sucesso!");
          setNome("");
          setSobrenome("");
          setEmail("");
          setTelefone("");
          setMessage("");
        })
        .catch((error) => {
          console.log("Error sending email:", error);
          toast.error("Falha ao enviar o e-mail.");
          setLoading(true);
        });
    } else {
      toast.error("Por favor, preencha todos os campos!");
      setLoading(true);
    }
  }

  return (
    <div className="main-container">
      <Navbar />
      <PageTitle title={"CONTATO"} color={"#f1f1f1"} height={35} width={35} />
      <div className="body">
        <div className="contact-area">
          <form onSubmit={handleSubmit}>
            <h2>FALE CONOSCO</h2>
            <input
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              type="text"
              placeholder="Sobrenome"
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
            />
            <input
              type="text"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
            <textarea
            type="text"
              placeholder="Digite sua mensagem..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit">{loading ? "Enviar" : "Enviando..."}</button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}
