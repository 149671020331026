import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlus, FiList, FiSearch, FiEdit2 } from "react-icons/fi";

import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  deleteDoc
} from "firebase/firestore";
import { db } from "../../services/FirebaseConnection";

import "./painel.css";

const listRef = collection(db, "programas");

export default function Painel() {
  const { logout } = useContext(AuthContext);

  const [programas, setProgramas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    async function loadProgramas() {
      const q = query(listRef);

      const querySnapshot = await getDocs(q);
     //Evita duplicar items durante desenvolvimento...
      setProgramas([]);

      await updateState(querySnapshot);

      setLoading(false);
    }
    loadProgramas();

    return () => {};
  }, []);

  async function updateState(querySnapshot) {
    const isCollectionEmpty = querySnapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      querySnapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          title: doc.data().title,
        });
      });

      setProgramas((programas) => [...programas, ...lista]);
    } else {
      setIsEmpty(true);
    }
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Programas">
          <FiList size={25} />
        </Title>
        <>
          {programas.length === 0 ? (
            <div className="container dashboard">
              <span>Nenhum programa encontrado.</span>
              <Link to="/adicionar-programas" className="adicionar-programas">
                <FiPlus color="#FFF" size={25} />
                Adicionar Programa
              </Link>
            </div>
          ) : (
            <>
              <Link to="/adicionar-programas" className="adicionar-programas">
                <FiPlus color="#FFF" size={25} />
                Adicionar Programa
              </Link>
              <table>
                <thead>
                  <tr>
                    <th scope="col">Nome do Programa</th>
                    <th scope="col">#</th>
                  </tr>
                </thead>
                <tbody>
                  {programas.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Programa">{item.title}</td>
                        <td data-label="#">
        
                          <Link to={`/editar-programa/${item.id}`}
                            className="action"
                            style={{ backgroundColor: "#f6a935" }}
                          >
                            <FiEdit2 color="#FFF" size={17} />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </>
      </div>
    </div>
  );
}
